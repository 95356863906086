import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import Matter from "matter-js";
import {
  Share2, // Better for sharing/syncing concept
  Heart, // More recognizable for health
  Calendar, // Simpler calendar icon
  ShieldCheck, // Better privacy icon with good proportions
} from "lucide-react";
import mixpanel from "mixpanel-browser";

// At the top of the file
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
if (!MIXPANEL_TOKEN) {
  console.error("Mixpanel token is missing!");
}

// Initialize Mixpanel at the top of your file
mixpanel.init(MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

// Add a console log to verify the token
console.log(
  "Mixpanel initialized with token:",
  MIXPANEL_TOKEN?.slice(0, 6) + "..."
);

// Emojis to use
const emojis = ["❤️", "😊", "🎉", "😍", "🌟", "🍀"];

const createEmojiCanvas = (emoji) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = 100;
  canvas.height = 100;

  // Set font and alignment
  ctx.font = "80px serif";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";

  // Draw the emoji
  ctx.fillText(emoji, canvas.width / 2, canvas.height / 2);

  // Return the canvas as a texture (data URL)
  return canvas.toDataURL();
};

const features = [
  {
    title: "Sync with Partner",
    description: "Share your calendar with your loved one",
  },
  {
    title: "Apple Health",
    description: "Seamlessly integrate with your health data",
  },
  {
    title: "Calendar Sync",
    description: "Auto-sync with Apple Calendar",
  },
  {
    title: "Privacy First",
    description: "Your data stays private, never sold to third parties",
  },
  {
    title: "Many more to come",
    description: "I'm eagerly waiting for feedback to add more features",
  },
];

// User Interaction Events
const trackEvents = {
  // Core tracking setup
  initUser: () => {
    console.log("Initializing Mixpanel user...");
    mixpanel.register({
      app_version: "beta",
      platform: "web",
      locale: navigator.language,
      viewport: `${window.innerWidth}x${window.innerHeight}`,
      referrer: document.referrer,
    });
    console.log("Mixpanel user initialized");
  },

  // Page Views
  trackPageView: (page) => {
    console.log("Tracking page view:", page);
    mixpanel.track("Page View", {
      page_name: page,
      url: window.location.href,
      referrer: document.referrer,
    });
  },

  // App Actions
  trackDownload: () => {
    mixpanel.track("Download Click", {
      source: "hero_section",
      platform: "iOS",
      type: "beta",
    });
  },

  // Feature Engagement
  trackFeatureView: (feature) => {
    mixpanel.track("Feature Interest", {
      feature_name: feature,
      interaction_type: "hover",
    });
  },

  // Legal Pages
  trackLegalView: (page) => {
    mixpanel.track("Legal Page View", {
      page_name: page,
    });
  },

  trackEmojiBurst: () => {
    mixpanel.track("Emoji Animation", {
      trigger: "download_hover",
    });
  },

  trackLinkClick: (linkType) => {
    mixpanel.track("Link Click", {
      link_type: linkType,
      url: window.location.href,
    });
  },
};

const MainContent = () => {
  const engineRef = useRef(null);
  const renderRef = useRef(null);
  const runnerRef = useRef(null);
  const emojiTexturesRef = useRef([]);
  let emojiInterval;
  const location = useLocation();

  const setupPhysics = () => {
    const engine = Matter.Engine.create();
    const runner = Matter.Runner.create();

    engine.world.gravity.y = 1;

    const render = Matter.Render.create({
      element: document.body,
      engine: engine,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        wireframes: false,
        background: "transparent",
      },
    });

    // Add these lines to prevent context menu blocking
    render.canvas.style.pointerEvents = "none";
    render.canvas.style.userSelect = "auto";

    render.canvas.style.position = "absolute";
    render.canvas.style.top = "0";
    render.canvas.style.left = "0";
    render.canvas.style.zIndex = "0";

    engineRef.current = engine;
    renderRef.current = render;
    runnerRef.current = runner;

    // Create ground and walls
    const ground = Matter.Bodies.rectangle(
      window.innerWidth / 2,
      window.innerHeight - 50,
      window.innerWidth,
      100,
      {
        isStatic: true,
        render: { fillStyle: "transparent" }, // Invisible ground
      }
    );
    const leftWall = Matter.Bodies.rectangle(
      -50,
      window.innerHeight / 2,
      100,
      window.innerHeight,
      { isStatic: true, render: { fillStyle: "transparent" } }
    );
    const rightWall = Matter.Bodies.rectangle(
      window.innerWidth + 50,
      window.innerHeight / 2,
      100,
      window.innerHeight,
      { isStatic: true, render: { fillStyle: "transparent" } }
    );

    Matter.World.add(engine.world, [ground, leftWall, rightWall]);

    Matter.Render.run(render);
    Matter.Runner.run(runner, engine); // Start the runner
  };

  const createEmoji = () => {
    const x = Math.random() * window.innerWidth;
    const emojiTexture =
      emojiTexturesRef.current[
        Math.floor(Math.random() * emojiTexturesRef.current.length)
      ];

    const body = Matter.Bodies.circle(x, 0, 30, {
      restitution: 0.6, // Slight bounce
      friction: 0.2, // Some friction for realistic stacking
      render: {
        sprite: {
          texture: emojiTexture,
          xScale: 0.6,
          yScale: 0.6,
        },
      },
    });

    Matter.World.add(engineRef.current.world, body);
  };

  const handleMouseEnter = () => {
    trackEvents.trackEmojiBurst();
    emojiInterval = setInterval(() => {
      createEmoji();
    }, 500);
  };

  const handleMouseLeave = () => {
    clearInterval(emojiInterval);
  };

  useEffect(() => {
    // Generate emoji textures
    emojiTexturesRef.current = emojis.map((emoji) => createEmojiCanvas(emoji));

    setupPhysics();

    return () => {
      if (renderRef.current) {
        Matter.Render.stop(renderRef.current);
        Matter.Runner.stop(runnerRef.current); // Stop the runner
        Matter.World.clear(engineRef.current.world);
        Matter.Engine.clear(engineRef.current);
        renderRef.current.canvas.remove();
        renderRef.current.canvas = null;
        renderRef.current.context = null;
        renderRef.current.textures = {};
      }
    };
  }, []);

  useEffect(() => {
    trackEvents.initUser();
    trackEvents.trackPageView(location?.pathname || "home");
  }, [location]);

  const handleDownloadClick = () => {
    trackEvents.trackDownload();
    // Optional: Track successful downloads separately
    // You might want to set up URL parameters to track successful installs
  };

  const handleFeatureHover = (featureTitle) => {
    trackEvents.trackFeatureView(featureTitle);
  };

  // For legal pages
  useEffect(() => {
    if (location?.pathname === "/privacy") {
      trackEvents.trackLegalView("privacy_policy");
    } else if (location?.pathname === "/terms") {
      trackEvents.trackLegalView("terms_of_service");
    }
  }, [location]);

  return (
    <main className="h-screen p-4 sm:p-6 md:p-8 flex flex-col justify-between relative overflow-hidden">
      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-purple-900/20 via-black to-black" />

      {/* Subtle gradient glow */}
      <div className="absolute inset-x-0 bottom-0 h-[70vh] bg-gradient-to-t from-purple-900/20 via-rose-900/10 to-transparent pointer-events-none" />

      {/* Main Content */}
      <div className="relative flex-1 flex items-start sm:items-center justify-center pt-[60px] sm:pt-0">
        <div className="max-w-3xl w-full px-6 sm:px-0">
          <div className="flex flex-col sm:flex-row sm:gap-16">
            {/* App Icon Column */}
            <div className="flex-shrink-0 mb-8 sm:mb-0">
              <img
                src="/fav.png"
                alt="Period or Not App Icon"
                className="w-24 h-24 rounded-[28%] shadow-xl ring-1 ring-white/10"
              />
            </div>

            {/* Content Column */}
            <div className="flex-1">
              {/* App Info */}
              <div className="mb-12">
                <h1 className="font-medium text-lg leading-tight text-white mb-1">
                  Period or Not
                </h1>
                <p className="font-medium text-base leading-tight text-[#999999]">
                  Simple (and fun) way to track period
                </p>

                <div className="mt-6">
                  <a
                    href="https://apps.apple.com/us/app/period-or-not/id6737966705"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block hover:opacity-80 active:scale-95 transition-all duration-300"
                    onClick={handleDownloadClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img
                      src="/download.svg"
                      alt="Download Beta"
                      className="w-48 h-auto"
                    />
                  </a>
                </div>
              </div>

              {/* Features list */}
              <div className="space-y-6">
                {features.map((feature, index) => (
                  <div
                    key={index}
                    className="group transition-all duration-300"
                    onMouseEnter={() => handleFeatureHover(feature.title)}
                  >
                    <h3 className="font-medium text-base leading-tight text-white mb-1">
                      {feature.title}
                    </h3>
                    <p className="font-medium text-base leading-tight text-[#999999]">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>

              {/* Footer Links - moved inside content column */}
              <div className="mt-12 space-x-6 text-sm">
                <a
                  href="/terms"
                  onClick={() => trackEvents.trackLinkClick("terms")}
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  Terms of Service
                </a>
                <a
                  href="/privacy"
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

// Main App Component
function AppContent() {
  const location = useLocation();
  const isLegalPage = ["/terms", "/privacy"].includes(location.pathname);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      {isLegalPage ? (
        <div className="container mx-auto px-4 py-6 sm:py-8 md:py-12 max-w-4xl">
          <Routes>
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
      ) : (
        <MainContent />
      )}
    </div>
  );
}

// Router Setup
function App() {
  useEffect(() => {
    mixpanel.track("App Loaded", {
      timestamp: new Date().toISOString(),
    });
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
