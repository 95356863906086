import React from "react";

const TermsOfService = () => {
  return (
    <div className="text-black bg-white p-8 rounded-lg shadow-lg max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
      <p className="mb-6 text-sm">Effective Date: February 12, 2025</p>

      <div className="space-y-6 text-sm">
        <p>
          Welcome to <strong>Period or Not</strong>! These Terms of Service
          ("Terms") govern your use of the app. By using the app, you agree to
          these Terms.
        </p>

        <section>
          <h2 className="text-lg font-bold mb-2">1. Eligibility</h2>
          <ul className="list-disc pl-6">
            <li>You must be at least 16 years old to use this app.</li>
            <li>
              If you are under the age of majority in your country, you may use
              the app only with parental or guardian consent.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-lg font-bold mb-2">2. Use of the App</h2>
          <ul className="list-disc pl-6">
            <li>The app is for personal, non-commercial use only.</li>
            <li>
              You are responsible for maintaining the confidentiality of your
              account credentials.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-lg font-bold mb-2">3. Premium Features</h2>
          <ul className="list-disc pl-6">
            <li>
              A one-time purchase unlocks premium features, such as [list
              specific features if needed].
            </li>
            <li>
              Payment is processed via the App Store. Refunds are subject to
              Apple's refund policy.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-lg font-bold mb-2">4. Health Disclaimer</h2>
          <p>
            This app is not a medical device and does not provide medical
            advice. Always consult a healthcare provider for health-related
            concerns.
          </p>
        </section>

        <section>
          <h2 className="text-lg font-bold mb-2">5. Prohibited Activities</h2>
          <ul className="list-disc pl-6">
            <li>Using the app for illegal purposes.</li>
            <li>Interfering with or disrupting the app or its servers.</li>
            <li>Reverse-engineering, decompiling, or exploiting the app.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-lg font-bold mb-2">6. Account Termination</h2>
          <p>
            We reserve the right to suspend or terminate accounts for violation
            of these Terms or illegal activity.
          </p>
        </section>

        <section>
          <h2 className="text-lg font-bold mb-2">7. Liability Disclaimer</h2>
          <p>
            The app is provided "as is" without warranties of any kind. To the
            maximum extent permitted by law, we are not responsible for damages
            arising from your use of the app, except as required by law.
          </p>
        </section>

        <section>
          <h2 className="text-lg font-bold mb-2">8. Governing Law</h2>
          <p>
            These Terms are governed by the laws of the European Union and your
            country of residence.
          </p>
        </section>

        <section>
          <h2 className="text-lg font-bold mb-2">9. Changes to the Terms</h2>
          <p>
            We may update these Terms periodically. Significant updates will be
            communicated within the app or via email.
          </p>
        </section>

        <section>
          <h2 className="text-lg font-bold mb-2">10. Contact Us</h2>
          <p>
            For questions or feedback about these Terms, email us at{" "}
            <a href="mailto:hi@apek.me" className="font-bold underline">
              hi@apek.me
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
